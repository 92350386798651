import { Outlet } from "remix";
import SearchBar from "~/components/SearchBar";

export type NextBusData = {
  DestinationCode: string; // "11009" last stop
  EstimatedArrival: string; // "2021-11-22T00:39:13+08:00"
  Feature: string; // "WAB" wheelchair acessible | empty/blank
  Latitude: string; // "1.3002310000000001"
  Load: string; // "SEA" seats available | "SDA" standing available | "LSD" limited standing
  Longitude: string; // "103.8417855"
  OriginCode: string; // "11009" first stop
  Type: string; // "SD" single deck | "DD" double deck | "BD" bendy
  VisitNumber: string; // "1" nth visit of vehicle at this bus stop
};

export type BusStopArrival = {
  ServiceNo: string; // "111"
  Operator: string; // "SBST"
  NextBus: NextBusData;
  NextBus2: NextBusData;
  NextBus3: NextBusData;
};

export default function Index(): JSX.Element {
  return (
    <div>
      <main className="py-4 sm:py-6 md:py-20">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-3xl lg:px-8">
          <h1 className="sr-only">BusSG</h1>
          <section aria-labelledby="searchbar">
            <h2 className="sr-only" id="results">
              Search bar
            </h2>
            <div>
              <label htmlFor="mobile-search" className="sr-only">
                Search
              </label>
              <SearchBar />
            </div>
          </section>

          <section aria-labelledby="results" className="mt-6 sm:mt-10">
            <h2 className="sr-only" id="results">
              Results
            </h2>
            <Outlet />
          </section>
        </div>
      </main>
    </div>
  );
}

import { useMemo, useRef, useState } from "react";
import type { ChangeEvent, FocusEvent } from "react";
import { useNavigate } from "remix";
import debounce from "lodash.debounce";

import SearchIcon from "@heroicons/react/solid/SearchIcon";
import { Popover } from "@headlessui/react";

import index, { busStopArr } from "~/data/bus";
import HighlightMatch from "./HighlightMatch";


export default function SearchBar(): JSX.Element {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchResult, setSearchResult] = useState<number[]>([]);
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const popoverPanelRef = useRef<HTMLDivElement | null>(null);
  const popoverContentRef = useRef<HTMLDivElement | null>(null);

  const changeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value;

    setSearchTerm(newValue);
    setSearchResult(index.search(newValue, 10) as number[]);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 250),
    []
  );

  const panelBlurHandler = (ev: FocusEvent<HTMLDivElement>) => {
    // relatedTarget is the element that is receiving focus.
    // currentTarget is the element that is losing focus.
    // if an element that is not a child of panel receives focus, close the search

    // TODO: check why this works when focus goes from panel to input
    // Answer: This works because input setSearchOpen(true) onFocus
    // Could alternatively check if inputRef is focused so setSearchOpen(false) doesn't need to run
    if (
      !ev.currentTarget.contains(ev.relatedTarget) &&
      !inputRef.current?.contains(ev.relatedTarget)
    ) {
      setSearchOpen(false);
    }
  };

  const inputBlurHandler = (ev: React.FocusEvent<HTMLDivElement>) => {
    // if an element that is not the input and not a child of panel receives focus, close the search
    if (
      !ev.currentTarget.contains(ev.relatedTarget) &&
      !popoverPanelRef.current?.contains(ev.relatedTarget)
    ) {
      setSearchOpen(false);
    }
  };

  const inputKeyDownHandler = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      // Happens only when entering in input.
      if (searchResult.length > 0) {
        navigate(`/${busStopArr[searchResult[0]].BusStopCode}`);
        setSearchOpen(false);
      }
    }

    if (ev.key === "ArrowDown") {
      ev.preventDefault();
      (popoverContentRef.current?.firstChild as HTMLDivElement)?.focus();
    }
  };

  return (
    <div className="relative bg-sepia-500 shadow-2xl rounded-xl">
      <Popover>
        <>
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <SearchIcon className="h-5 w-5 text-sepia-800" aria-hidden="true" />
          </div>
          {/* TODO: /b search for bus specific route info */}
          <input
            id="mobile-search"
            className="h-12 w-full border-0 bg-transparent pl-11 pr-10 text-sepia-900 placeholder-sepia-800 focus:ring-0"
            placeholder="Search"
            type="text"
            name="search"
            autoComplete="off"
            ref={inputRef}
            onChange={debouncedChangeHandler}
            onKeyDown={inputKeyDownHandler}
            onBlur={inputBlurHandler}
            onFocus={() => setSearchOpen(true)}
          />
          {/* TODO: Add x to clear input. Figure out useState value vs ev.value with debounce */}
          {/* <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={() => setSearchTerm(null)}
          >
            <XCircleIcon
              className="h-5 w-5 text-sepia-800"
              aria-hidden="true"
            />
          </div> */}
          {searchOpen && searchResult.length > 0 && (
            <Popover.Panel
              className="absolute z-10 w-full mt-3"
              ref={popoverPanelRef}
              tabIndex={-1}
              // on blur but not for child
              onBlur={panelBlurHandler}
              static
            >
              <div className="overflow-y-auto shadow-lg ring-1 ring-black ring-opacity-5 max-h-96 rounded-xl">
                <div
                  className="relative grid gap-2 bg-sepia-500 p-3"
                  ref={popoverContentRef}
                >
                  {searchResult.map((itemIdx) => {
                    const { BusStopCode, Description, RoadName } =
                      busStopArr[itemIdx];

                    const HighlightedDescription = (
                      <HighlightMatch
                        title={Description}
                        q={searchTerm ?? ""}
                      />
                    );

                    const HighlightedBusStopCode = (
                      <HighlightMatch
                        title={BusStopCode}
                        q={searchTerm ?? ""}
                      />
                    );

                    const HighlightedRoadName = (
                      <HighlightMatch title={RoadName} q={searchTerm ?? ""} />
                    );
                    return (
                      // TODO: On focus on input, put focus on first child on keydown
                      // TODO: On focus on popover, put focus on first child on keydown
                      // TODO: On up down arrow, check whether popover is focused and run:
                      // The key is e.currentTarget.nextSibling.focus() and e.currentTarget.previousSibling.focus()
                      <div
                        key={BusStopCode}
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          navigate(`/${BusStopCode}`);
                          setSearchOpen(false);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            navigate(`/${BusStopCode}`);
                            setSearchOpen(false);
                          }

                          if (e.key === "ArrowDown") {
                            e.currentTarget.nextSibling &&
                              (
                                e.currentTarget.nextSibling as HTMLDivElement
                              ).focus();
                          }
                          if (e.key === "ArrowUp") {
                            if (e.currentTarget.previousSibling) {
                              (
                                e.currentTarget
                                  .previousSibling as HTMLDivElement
                              ).focus();
                            } else {
                              inputRef.current?.focus();
                            }
                          }
                        }}
                        className="flex cursor-pointer items-center py-2 px-4 transition duration-150 ease-in-out rounded hover:bg-sepia-400 focus:outline-none focus-visible:ring-1 focus-visible:ring-sepia-900 focus-visible:ring-opacity-50"
                      >
                        <div>
                          <p className="text-sm font-semibold text-sepia-900">
                            {HighlightedDescription}
                          </p>
                          <p className="text-sm text-sepia-800">
                            {HighlightedBusStopCode} · {HighlightedRoadName}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Popover.Panel>
          )}
        </>
      </Popover>
    </div>
  );
}
